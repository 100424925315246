.labled-input__label {
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
}

.labled-input__label div {
  height: 40px;
  padding: 0 3px;
  display: flex;
  flex-direction: column;
}

.labled-input__input {
  width: 190px;
  color: #0d1117;
  background-color: #c9d1d9;
  border: none;
  border-radius: 5px;
  outline-color: #c9d1d9;
}

.labled-input__input[type="file" i] {
  background-color: #0d1117;
  color: #c9d1d9;
}

.labled-input__input[type="checkbox" i] {
  width: 150px;
}

.labled-input__error {
  text-align: center;
  color: red;
}