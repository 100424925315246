.item-card__wrapper {
  margin: 1.25rem;
  width: 300px;
  padding: .5rem;
  position: relative;
  border-radius: 5px;
  box-shadow: 1px 1px 8px 5px rgba(0, 0, 0, .2);
  color: #c9d1d9;
  background-color: #161b22;
  font-size: 1rem;
  cursor: pointer;
  transition: .5s;
}

.item-card__wrapper:hover {
  box-shadow: 1px 1px 15px 10px rgba(150, 150, 150, .5);
}

.item-card__wrapper h2 {
  margin: 1rem 0;
  padding-top: .5rem;
  padding-left: .5rem;
  font-size: 1.5rem;
}

.item-card__img {
  position: relative;
  overflow: hidden;
}

.item-card__img:hover .item-card__overview {
  top: 0;
  visibility: visible;
}

.item-card__img img {
  width: 100%;
}

.item-card__img .item-card__overview {
  height: 100%;
  padding: 0.5rem;
  position: absolute;
  top: -100%;
  left: 0;
  visibility: hidden;
  background: rgba(201, 209, 217, 0.8);
  color: #161b22;
  transition: .7s;
  overflow-y: auto;
}

.item-card__img .item-card h3 {
  margin: 0 .5rem;
  padding: .5rem 0;
  font-size: 1rem;
  font-weight: 700;
}

.item-card__info {
  padding: .5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: .5rem;
  background: transparent;
  font-size: 1rem;
  color: #c9d1d9;
}

.item-card__info p {
  margin: .5rem 0;
}

.item-card__rating {
  width: 2.5rem;
  height: 1.5rem;
  padding-top: .2rem;
  position: absolute;
  top: -.7rem;
  right: -.5rem;
  border-radius: 10px;
  background: #35ac35;
  text-align: center;
  font-size: 1rem;
}

