.header {
  padding: 0 10px;
  background-color: #161b22;
  color: #c9d1d9;
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.header h1 {
  margin: 0;
  padding-top: .25rem;
}

.nav__list {
  margin-left: auto;
  margin-right: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-content: center;
  gap: 10px;
}

.nav__list .link {
  color: #c9d1d9;
}

.nav__list .link:hover {
  color: white;
}