.items-cards-list {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  overflow-y: auto;
}

.items-cards-list::-webkit-scrollbar {
  width: .7rem;
  background-color: #0d1117;
  border-color: #30363d;
}

.items-cards-list::-webkit-scrollbar {
  border-color: #8a939c;
}

.items-cards-list::-webkit-scrollbar-thumb {
  background-color: #c9d1d9;
  transition: .3s;
}

.items-cards-list::-webkit-scrollbar-thumb:hover {
  background-color: white;
}

.items-cards-list::-webkit-scrollbar-button:vertical:start:decrement {
  background: linear-gradient(120deg, #161b22 40%, rgba(0, 0, 0, 0) 41%),
  linear-gradient(240deg, #161b22 40%, rgba(0, 0, 0, 0) 41%),
  linear-gradient(0deg, #161b22 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #c9d1d9;
  transition: .3s;
}

.items-cards-list::-webkit-scrollbar-button:vertical:start:decrement:hover {
  background-color: white;
}

.items-cards-list::-webkit-scrollbar-button:vertical:end:increment {
  background: linear-gradient(300deg, #161b22 40%, rgba(0, 0, 0, 0) 41%),
  linear-gradient(60deg, #161b22 40%, rgba(0, 0, 0, 0) 41%),
  linear-gradient(180deg, #161b22 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #c9d1d9;
}